import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
const firebaseConfig = {
	apiKey: "AIzaSyBO1rzgql95xS1iPNQAQrUf9UeypE5WPbg",
	authDomain: "whatsapp-clone-e31a2.firebaseapp.com",
	databaseURL: "https://whatsapp-clone-e31a2.firebaseio.com",
	projectId: "whatsapp-clone-e31a2",
	storageBucket: "whatsapp-clone-e31a2.appspot.com",
	messagingSenderId: "190977162787",
	appId: "1:190977162787:web:b2e36a0d1a4ec0c426bb96",
};
const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();
const auth = app.auth();
const provider = new firebase.auth.GoogleAuthProvider();
export { auth, provider };
export default db;
